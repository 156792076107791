import {BriefPanelName, PanelTypeEnum, Unit, VAxisType} from '@balrog/component/common';
import {ProfileInterface} from './interface/profile.interface';

export class WindTurbineControlProfile implements ProfileInterface {
    statuses = {
        GRZALKI: { title: 'Grzałki',},
        POMPA_BUFOR: { title: 'Pompa bufor',},
        HAMULEC_MECHANICZNY: { title: 'Hamulec mechaniczy',},
        HAMULEC_FAZOWY: { title: 'Hamulec fazowy',},
    };
    // alarmy
    alarms = {
        BLAD_PAMIECI_NASTAW: {title: 'Błąd pamięci nastaw'},
        BLAD_PAMIECI_PROGRAMU: {title: 'Błąd pamięci programu'},
    };

    briefPanel = {
        name: BriefPanelName.ELEKTROMIZ_WIND_TURBINE_CONTROL
    };

    dashBoard = {
        panelType: PanelTypeEnum.ELEKTROMIZ_WIND_TURBINE_CONTROL
    };

    historyBoard = {
        title: 'Moc [W]',
        content: [
            {
                paramId: 'WTC_VAL_GRZALKA_MOC',
                unit: Unit.W,
                vAxisType: VAxisType.POWER_W.valueOf(),
            },
            {
                paramId: 'WTC_VAL_TURBINA_ZUZYCIE_MOCY',
                unit: Unit.W,
                vAxisType: VAxisType.POWER_W.valueOf(),
            },
            {
                paramId: 'WTC_VAL_INVERTER_MOC',
                unit: Unit.W,
                vAxisType: VAxisType.POWER_W.valueOf(),
            },
            {
                paramId: 'WTC_VAL_TURBINA_RPM',
                unit: Unit.RPM,
                vAxisType: VAxisType.RPM.valueOf(),
            },
            {
                paramId: 'WTC_VAL_GONDOLA_ROTACJA',
                unit: Unit.DEGREE,
                vAxisType: VAxisType.DEGREE.valueOf(),
            },
            {
                paramId: 'WTC_VAL_WIATR_KIERUNEK',
                unit: Unit.DEGREE,
                vAxisType: VAxisType.DEGREE.valueOf(),
            },
            {
                paramId: 'WTC_VAL_WIATR_PREDKOSC',
                unit: Unit.MS,
                vAxisType: VAxisType.MS.valueOf(),
            },
            {
                paramId: 'WTC_VAL_INVERTER_NAPIECIE_DC',
                unit: Unit.V,
                vAxisType: VAxisType.V.valueOf(),
            },
            {
                paramId: 'WTC_VAL_NAPIECIE_SYSTEMOWE',
                unit: Unit.V,
                vAxisType: VAxisType.V.valueOf(),
            },
        ]
    };

    listBoard = {
        title: 'Konfiguracja',
        content: [
        {
            paramId: 'WERSJA_STEROWNIKA',
            unit: Unit.NONE
        },
        // {
        //     title: 'Odczytane wartości',
        //     type: 'MENU',
        //     content: 
        //     [
                {
                    paramId: 'WTC_VAL_NAPIECIE_SYSTEMOWE',
                    unit: Unit.V,
                    step: 0.1
                },
                {
                    paramId: 'WTC_VAL_GRZALKA_MOC',
                    unit: Unit.W,
                },
                {
                    paramId: 'WTC_VAL_GRZALKA_PWM',
                    unit: Unit.W,
                },
                {
                    paramId: 'WTC_VAL_TURBINA_RPM',
                    unit: Unit.RPM,
                },
                {
                    paramId: 'WTC_VAL_TURBINA_MOC',
                    unit: Unit.W,
                },
                {
                    paramId: 'WTC_VAL_TURBINA_ZUZYCIE_MOCY',
                    unit: Unit.W,
                },
                {
                    paramId: 'WTC_VAL_GONDOLA_ROTACJA',
                    unit: Unit.DEGREE,
                },
                {
                    paramId: 'WTC_VAL_WIATR_PREDKOSC',
                    unit: Unit.MS,
                },
                {
                    paramId: 'WTC_VAL_WIATR_KIERUNEK',
                    unit: Unit.DEGREE,
                },
                {
                    paramId: 'WTC_VAL_NAPIECIE_SYSTEMOWE',
                    unit: Unit.V,
                    step: 0.1,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_MOC',
                    unit: Unit.W,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_NASTAWA_MOC',
                    unit: Unit.W,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_NAPIECIE_DC',
                    unit: Unit.V,
                    step: 0.1,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_NAPIECIE_L1',
                    unit: Unit.V,
                    step: 0.1,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_NAPIECIE_L2',
                    unit: Unit.V,
                    step: 0.1,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_NAPIECIE_L3',
                    unit: Unit.V,
                    step: 0.1,
                },
        //     ]
        // },
        // {
        //     title: 'Nastawy',
        //     type: 'MENU',
        //     content: [
                    {
                        paramId: 'WTC_SETP_NAPIECIE_SYSTEMOWE',
                        displayType: 'ITEM-SWITCH',
                        options: [{value: '0', name: '24V'}, {value: '1', name: '48V'}, {value: '2', name: '400V'}]
                    },
                    {
                        paramId: 'WTC_SETP_NAPIECIE_MAX_24V',
                        unit: Unit.V,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_NAPIECIE_MAX_48V',
                        unit: Unit.V,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_NAPIECIE_MAX_400V',
                        unit: Unit.V,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_BUFOR_TEMP_WL_POMPY',
                        unit: Unit.C,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_BUFOR_HISTEREZA_POMPY',
                        unit: Unit.C,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_CZAS',
                        unit: Unit.SEC,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_OBROT_CZAS',
                        unit: Unit.SEC,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_INVERTER_CZAS_POLACZENIA',
                        unit: Unit.SEC,
                    },
                    {
                        paramId: 'WTC_SETP_INVERTER_MAX_MOC',
                        unit: Unit.KW,
                    },
                    {
                        paramId: 'WTC_SETP_NAPIECIE_SYSTEMOWE_KOREKTA',
                        unit: Unit.V,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_TURBINA_POLA_MAGNETYCZNE',
                        unit: Unit.NONE,
                    },
                    {
                        paramId: 'WTC_SETP_INVERTER_MOC_PROPORCJONALNA',
                        unit: Unit.PERCENTAGE,
                    },
                    {
                        paramId: 'WTC_SETP_GRZALKA_PUNKT_STARTOWY',
                        unit: Unit.NONE,
                    },
                    {
                        title: 'Krzywa mocy',
                        type: 'MENU',
                        content: this.CreatePowerCurve()
                    },
                    {
                        title: 'Krzywa napięcia',
                        type: 'MENU',
                        content: this.CreateVoltageCurve()
                    },
        // ]},
    ]};

    scheduleBoard = null;

    CreatePowerCurve()
    {
        const content = []
        const powerPointsAmount = 12
        for(let i = 1; i <= powerPointsAmount; i++)
        {
            const rpmPointDesc = 
            {
                paramId: 'WTC_SETP_KRZYWA_MOCY_RPM_P'+i, 
                unit: Unit.RPM,
            }
            content.push(rpmPointDesc)
            const powerPointDesc = 
            {
                paramId: 'WTC_SETP_KRZYWA_MOCY_W_P'+i,
                unit: Unit.W,
            }
            content.push(powerPointDesc)
        }
        return content
    }

    CreateVoltageCurve()
    {
        const content = []
        const voltagePointsAmount = 12
        for(let i = 1; i <= voltagePointsAmount; i++)
        {
            const rpmPointDesc = 
            {
                paramId: 'WTC_SETP_KRZYWA_NAPIECIA_V_P'+i, 
                unit: Unit.V,
                step: 0.1,
            }
            content.push(rpmPointDesc)
            const powerPointDesc = 
            {
                paramId: 'WTC_SETP_KRZYWA_NAPIECIA_A_P'+i, 
                unit: Unit.A,
                step: 0.1,
            }
            content.push(powerPointDesc)
        }
        return content
    }
}
