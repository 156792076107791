export enum PanelTypeEnum {
    'ELEKTROMIZ_MINISTER_PELET',
    'ELEKTROMIZ_MINISTER_MAX',
    'ELEKTROMIZ_MINISTER_ELEKTR',
    'ELEKTROMIZ_LFP_WSTER',
    'ELEKTROMIZ_SOLAR',
    'ELEKTROMIZ_COBRA',
    'HABA_MULTIBIO',
    'SMARTVENT',
    'LFP_EPOMP',
    'ELEKTROMIZ_MINISTER_PID',
    'TWEETOP_POMPA',
    'TWEETOP_OP',
    'TWEETOP_POMPA_V2',
    'ELEKTROMIZ_MINISTER_ZG',
    'ELEKTROMIZ_WIND_TURBINE_CONTROL',
}
